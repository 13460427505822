import React from "react";
import theme from "theme";
import { Theme, Section, Image, Text, Strong, Box, Link, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
import { AiFillLinkedin } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Humans for AI
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Home | Humans for AI"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI%20Favicon.png?v=2024-02-10T08:45:31.376Z"} type={"image/x-icon"} />
		</Helmet>
		<Section quarkly-title="Header" align-items="center" justify-content="center" padding="16px 0 16px 0">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="inline"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				align-items="center"
				justify-items="center"
				align-content="center"
			/>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end" margin="25px 0px 0px 0px">
				<Override
					slot="icon"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					md-height="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					md-width="100%"
					lg-transform="translateY(0px) translateX(0px)"
					md-left={0}
					padding="0px 0 0px 0"
					align-items="center"
					filterMode="include"
					filterPages="/aisc,/team,/about"
				>
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						hover-opacity="1"
						md-transition="opacity .15s ease 0s"
						md-font="16px/24px sans-serif"
						font="--base"
						color="--dark"
						opacity=".5"
						transition="opacity .15s ease 0s"
						text-transform="initial"
						md-color="--dark"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						opacity="1"
						color="--primary"
						cursor="default"
						md-opacity="1"
						md-cursor="default"
					/>
					<Override
						slot="item"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="link-work">
						AI Student Collective
					</Override>
					<Override slot="link-team">
						Team
					</Override>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
			</Components.BurgerMenu>
		</Section>
		<Section
			lg-padding="25px 0 25px 0"
			sm-padding="0px 0 25px 0"
			justify-content="center"
			padding="0px 0 30pxpx 0"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
			border-color="#000000"
			display="flex"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				width="100%"
				background="rgba(5, 5, 5, 0) url()"
				height="620px"
				justify-content="center"
				sm-width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				align-items="center"
				lg-height="520px"
				md-height="420px"
				md-padding="0px 24px 0px 24px"
				color="rgba(0, 0, 0, 0)"
			/>
			<Image src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21:53:55.077Z" display="block" srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=3200 3200w" sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw" />
			<Text
				sm-text-align="center"
				sm-width="80%"
				opacity="0.7"
				md-text-align="center"
				font="--lead"
				color="--dark"
				margin="0px 0px 16px 0px"
			>
				We Demystify AI.
			</Text>
		</Section>
		<Section
			sm-padding="24px 0 24px 0"
			background="#ffffff"
			border-style="solid"
			box-sizing="border-box"
			justify-content="center"
			padding="50px 0px 50px 0px"
			border-color="--color-lightD2"
			border-width="0px 0px 0px 0px"
			lg-padding="50px 30px 50px 30px"
			quarkly-title="FAQ"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 48px 0px 48px"
				md-margin="0px 16px 0px 16px"
				align-items="center"
			/>
			<Text
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 10px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 36px 0px"
			>
				Our Mission
			</Text>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				lg-text-align="center"
			>
				MAKE AI B.O.L.D.
			</Text>
			<Box
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box>
					<Text
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
					>
						Balanced
					</Text>
					<Text
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Balanced
						</Strong>
						{" "}AI constitutes a commitment to a stable ecosystem of technological innovation, business leadership, social equity, and community development.
					</Text>
				</Box>
				<Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
					>
						Open
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						We want to{" "}
						<Strong>
							open
						</Strong>
						{" "}artificial intelligence to all in an effort to increase accessibility and create meaningful growth opportunities.
					</Text>
				</Box>
				<Box>
					<Text
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						Literate
					</Text>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
					>
						We believe that AI{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							literacy
						</Strong>
						{" "}is a human right. Democratizing AI requires accessible educational initiatives and professional development opportunities.
					</Text>
				</Box>
				<Box>
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Diverse
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						There is a{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							diversity
						</Strong>
						{" "}crisis in AI. Unbiased, ethical, and fair models require an inclusive workforce that reflects the diversity of our society.
					</Text>
				</Box>
			</Box>
			<Link
				text-align="center"
				margin="48px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/about"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				Learn More
			</Link>
		</Section>
		<Section
			padding="50px 0px 50px 0px"
			quarkly-title="Works"
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 0px 0px 0px"
				align-items="center"
			/>
			<Text
				color="--dark"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				opacity="0.6"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				font="--base"
			>
				Moments from
			</Text>
			<Text
				text-align="center"
				lg-text-align="center"
				md-margin="0px 0px 36px 0px"
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 48px 0px"
				color="--dark"
			>
				The AI Student Collective
			</Text>
			<Box
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
			>
				<Image src="https://i.imgur.com/rOWMyRa.png" display="block" width="100%" />
				<Image src="https://i.imgur.com/jps2zcj.png" display="block" width="100%" />
				<Image src="https://i.imgur.com/NpUOMEA.png" display="block" width="100%" />
				<Image display="block" width="100%" height="auto" src="https://i.imgur.com/kz6no0C.png" />
				<Image display="block" width="100%" height="auto" src="https://i.imgur.com/STJn9Bl.jpg" />
				<Image src="https://i.imgur.com/FlDttde.png" display="block" width="100%" />
			</Box>
			<Link
				text-align="center"
				margin="48px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/aisc"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				Learn More
			</Link>
		</Section>
		<Section
			quarkly-title="USP"
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Box
				justify-content="center"
				flex-direction="column"
				md-min-height="360px"
				min-height="480px"
				padding="36px 24px 36px 24px"
				align-items="center"
				sm-min-height="280px"
				display="flex"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				lg-min-height="420px"
			>
				<Text
					color="--light"
					md-font="--headline3"
					margin="10px 0px 15px 0px"
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
				>
					Join the Team
				</Text>
				<Text
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
					margin="0px 0px 28px 0px"
					font="--lead"
				>
					We're always looking to add dedicated volunteers to our team in a variety of different roles and amazing companies to help sponsor our initiatives!{"  "}
				</Text>
				<Link
					text-align="center"
					margin="0 0px 0px 0px"
					hover-transform="translateY(-4px)"
					href="/team"
					color="--dark"
					padding="8px 18px 8px 18px"
					font="--base"
					transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
					md-margin="36px 0px 0px 0px"
					text-decoration-line="initial"
					letter-spacing="0.5px"
					background="--color-light"
					lg-margin="24px 0px 0px 0px"
				>
					Get Involved!
				</Link>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Box
				grid-gap="32px"
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="50px"
						height="50px"
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21:53:55.077Z"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						white-space="nowrap"
						font="--base"
						opacity="0.6"
						text-align="left"
						margin="1px 0px 0px 10px"
						href="/index#"
						color="--dark"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Humans for AI
					</Link>
				</Box>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap" justify-content="flex-end">
					<LinkBox href="https://www.linkedin.com/company/humansforai/" target="_blank">
						<Icon
							category="ai"
							icon={AiFillLinkedin}
							size="64px"
							padding="0px 3% 0px 3%"
							width="50px"
							height="50px"
							pointer-events="auto"
						/>
					</LinkBox>
					<LinkBox href="https://medium.com/@humansforai" target="_blank">
						<Image src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/icons8-medium-50.svg?v=2023-12-18T23:16:50.399Z" display="block" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
	</Theme>;
});